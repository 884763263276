* {
  font-family: -apple-system, BlinkMacSystemFont, "Avenir Next", "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, "Avenir Next", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.def-toast {
  background-color: #ffcab1 !important;
  color: #000 !important;
}

.def-toast .Toastify__toast-body {
  margin-left: 20px;
}

.custom-tooltip {
  display: inline-block !important;
  max-width: 350px;
  padding: 15px;
  background-color: #d8d8d8 !important;
  color: #4a4a4a !important;
  opacity: 0.97 !important;
  font-size: 14px;
}

.custom-tooltip p {
  margin: 0;
}

.custom-tooltip .margin_bottom {
  margin-bottom: 1em;
}

.custom-tooltip__calendar {
  display: inline-block !important;
  max-width: 450px;
  padding: 15px;
  background-color: #d8d8d8 !important;
  color: #4a4a4a !important;
  opacity: 0.97 !important;
  font-size: 14px;
}

.custom-tooltip__calendar::after {
  border-right-color: #d8d8d8 !important;
}

.custom-tooltip::after {
  border-right-color: #d8d8d8 !important;
}

.custom-tooltip.place-bottom:after {
  border-right-color: transparent !important;
  border-bottom-color: #d8d8d8 !important;
}

.custom-tooltip {
  display: inline-block !important;
  max-width: 350px;
  padding: 15px;
  background-color: #d8d8d8 !important;
  color: #4a4a4a !important;
  opacity: 0.97 !important;
  font-size: 14px;
}

.custom-tooltip.toast {
  display: inline-block !important;
  max-width: 350px;
  padding: 15px;
  background-color: #ffcab1 !important;
  color: #000 !important;
  opacity: 0.97 !important;
  font-size: 14px;
}

.custom-tooltip.place-bottom:after {
  border-right-color: transparent !important;
  border-bottom-color: #ffcab1 !important;
}


