html {
  box-sizing: border-box;
  font-size: 14px;
}

body html,
body {
  height: 100%;
  margin: 0;
}

#root {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
.top-bar {
  display: flex;
  flex-direction: row;
  border-bottom: 4px #a60c46 solid;
  position: fixed;
  white-space: nowrap;
  min-width: 1000px;
  text-decoration: none;
  min-width: 100%;
  background-color: white;
  z-index: 20;
  height: 54px;
}

.menu-tabs {
  display: flex;
  flex: 7;
  align-items: center;
  overflow: hidden;
  flex-direction: row;
  margin-right: -10px;
  margin-left: 30px;
  font-family: Helvetica, Arial, sans-serif;
  text-decoration: none;
}

.tab {
  padding-left: 10px;
  padding-right: 10px;

  padding-bottom: 10px;
  padding-top: 30px;
  margin-top: -20px;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
  outline: none;
  text-decoration: none;
  width: 110px;
  text-align: center;
}
.tab:hover {
  background-color: #a60c46;
  color: white;
  padding-bottom: 20px;
  margin-bottom: -10px;
  text-decoration: none;
}

.route {
  color: inherit;
  text-decoration: none;
}

.route-active {
  background-color: #a60c46;
  color: white !important;
  padding-bottom: 20px !important;
  margin-bottom: -10px !important;
  text-decoration: none !important;
}

.feedback-modal div {
  padding: 2px 10px !important;
}

.radio {
  margin-top: 0px !important;
}

.right-menu-options {
  display: flex;
  flex: 2;
  align-items: center;
  flex-direction: row;
  font-family: Helvetica, Arial, sans-serif;
  justify-content: flex-end;
  padding: 0px 60px 0px 0px;
}

.right-menu-options row {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}

.option {
  flex-shrink: 1;
  width: auto;
  padding: 15px;
}
.invite-clients {
  background-color: #ef3c52;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.logo img {
  max-height: 50px;
  max-width: 67px;
  padding-left: 20px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo {
  min-width: 170px;
  display: flex;
  flex: 1;
  margin-left: 10px;
  margin-right: 30px;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__content {
  display: none;
  position: absolute;
  right: 0px;
  z-index: 1;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
}

.dropdown--active .dropdown__content {
  display: block;
  z-index: 99999;
}

.ReactModal__Overlay {
  z-index: 9999;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
